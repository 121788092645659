import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  fetchProjectsForAE,
  resetProjectsPage,
  updateFilters,
  updateQuery
} from 'actions/projects'
import ProjectsSection from 'components/home-page/views/sections/projects'

const mapStateToProps = (state, ownProps) => ({
  meta: state.homePageRevision.revision.sections[ownProps.sectionId].meta,
  theme: state.homePageRevision.revision.sections[ownProps.sectionId].theme,
  anonymousToken: state.currentUser.adapter.token
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProjectsForAE,
      resetProjectsPage,
      updateFilters,
      updateQuery
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsSection)
