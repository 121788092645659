import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setCurrentSectionId,
  showAddNewSectionDrawer,
  changeHomePageSectionKey,
} from 'actions/home-page-revision';
import HomePageView from 'components/home-page/views';

const mapStateToProps = (state) => ({
  brandColor: state.theme.theme.brandColor,
  homePageMeta: state.homePageRevision.revision.meta,
  homePageSections: state.homePageRevision.revision.sections,
  headingFont: state.theme.theme.titleFont,
  site: state.currentSite.site,
  bodyFont: state.theme.theme.contentFont,
  activeKey: state.homePageRevision.activeHomePageKey,
  liquidTemplates: state.liquidTemplates.liquidTemplates,
  isCookiePolicyEnabled:
    state.features.features.hasOwnProperty('cookie_policy') &&
    state.features.features.cookie_policy.enabled,
  isReadSpeakerEnabled:
    state.features.features.hasOwnProperty('readspeaker') &&
    state.features.features.readspeaker.enabled,
  isGoogleAnalyticsEnabled: state.siteSettings.settings.trafficTrackingEnabled,
  googleAnalyticsAccount: state.siteSettings.settings.googleAnalyticsAccount,
  customTrackingCode: state.siteSettings.settings.customTrackingCode,
  customHeadCode: state.siteSettings.settings.customHeadCode,
  dataTestId: 'participation-ae-homepage-views',
  isnewCookieConsentManagerEnabled:
    state.features.features.hasOwnProperty('new_cookie_consent_manager') &&
    state.features.features.new_cookie_consent_manager.enabled,
  isUserTrackingAnalyticsEnabled:
    state.features.features.hasOwnProperty('disable_google_analytics') &&
    !state.features.features.disable_google_analytics.enabled,
  isPFEAuthenticationEnabled:
    state.features.features.hasOwnProperty('enable_pfe_authentication') &&
    state.features.features.enable_pfe_authentication.enabled,
  isPFEIdeasEnabled:
    state.features.features.hasOwnProperty('enablePfeIdeas') &&
    state.features.features.enablePfeIdeas.enabled,
  nonce: state.homePageRevision.nonce,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentSectionId,
      showAddNewSectionDrawer,
      changeHomePageSectionKey,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(HomePageView);
